<div class="page-container">
  <div class="p-grid">
    <div class="p-col-6">
      <p-card>
        <h4>Skapa kommentar</h4>
        <p>
          Här kan du skriva kommentarer som blir knutna till detta projekt.
          Kommentarerna visas inte för kunden, men de finns tillgängliga för
          alla inom bolaget. I appen syns de längst ner i projektet.
        </p>
        <textarea pInputTextarea [(ngModel)]="commentText"></textarea>

        <div class="p-d-flex p-jc-end p-col-12 p-pr-0 p-pb-0">
          <button
            pButton
            class="p-button-primary-hyperion"
            (click)="create(commentText, CommentsAccessLevelEnum.ALL_USERS)"
            label="Skapa"
            icon="pi pi-plus"
            type="button"
          ></button>
        </div>
      </p-card>

      <div class="p-mt-3" *ngFor="let comment of projectComments | async">
        <p-card>
          <h4 class="p-mb-3">
            Skapad {{ comment.created }} av {{ comment.user.firstName }}
            {{ comment.user.lastName }}
          </h4>
          <textarea #commentText pInputTextarea>{{ comment.comment }}</textarea>

          <div class="p-d-flex p-jc-end p-col-12 p-pr-0 p-pb-0">
            <button
              pButton
              class="p-button-danger-inverted"
              (click)="delete(comment)"
              label="Radera"
              icon="pi pi-trash"
              type="button"
            ></button>

            <button
              pButton
              class="p-button-primary-inverted p-ml-2"
              (click)="update(comment, commentText.value)"
              label="Uppdatera"
              icon="pi pi-save"
              type="button"
            ></button>
          </div>
        </p-card>
      </div>
    </div>

    <div class="p-col-6" *ngIf="currentUserIsAdmin">
      <p-card class="admin-comment">
        <h4>Skapa kommentar för endast administratörer</h4>
        <p>
          Här kan du skriva kommentarer som blir knutna till detta projekt.
          Dessa kommentarer syns endast för administratörer på hemsidan, inte i
          appen.
        </p>
        <textarea pInputTextarea [(ngModel)]="commentTextAdminOnly"></textarea>

        <div class="p-d-flex p-jc-end p-col-12 p-pr-0 p-pb-0">
          <button
            pButton
            class="p-button-primary-hyperion"
            (click)="
              create(commentTextAdminOnly, CommentsAccessLevelEnum.ADMINS_ONLY)
            "
            label="Skapa"
            icon="pi pi-plus"
            type="button"
          ></button>
        </div>
      </p-card>

      <div
        class="p-mt-3"
        *ngFor="let comment of projectCommentsAdminOnly | async"
      >
        <p-card
          class="admin-comment"
          *ngIf="
            comment.accessLevel !== CommentsAccessLevelEnum.SYSTEM_MESSAGES
          "
        >
          <h4 class="p-mb-3">
            Skapad {{ comment.created }} av {{ comment.user.firstName }}
            {{ comment.user.lastName }}
          </h4>

          <div *ngIf="+comment.user.id !== currentUserId">
            <p>{{ comment.comment }}</p>
          </div>

          <div *ngIf="+comment.user.id === currentUserId">
            <textarea #commentTextAdminOnly pInputTextarea>{{
              comment.comment
            }}</textarea>

            <div class="p-d-flex p-jc-end p-col-12 p-pr-0 p-pb-0">
              <button
                pButton
                class="p-button-danger-inverted"
                (click)="delete(comment)"
                label="Radera"
                icon="pi pi-trash"
                type="button"
              ></button>

              <button
                pButton
                class="p-button-primary-inverted p-ml-2"
                (click)="update(comment, commentTextAdminOnly.value)"
                label="Uppdatera"
                icon="pi pi-save"
                type="button"
              ></button>
            </div>
          </div>
        </p-card>

        <p-card
          class="admin-comment"
          *ngIf="
            comment.accessLevel === CommentsAccessLevelEnum.SYSTEM_MESSAGES
          "
        >
          <p class="p-text-italic">
            Systemmeddelande skapad {{ comment.created }} av
            {{ comment.user.firstName }} {{ comment.user.lastName }}
          </p>
          <p class="p-text-italic">
            {{ comment.comment }}
          </p>
        </p-card>
      </div>
    </div>
  </div>
</div>
