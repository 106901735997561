import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';

/**
 * This Service polls the server for a new version, and asks the user to reload the page.
 *
 * Gracefully stolen from the blogpost linked below.
 * @source https://blog.nodeswat.com/automagic-reload-for-clients-after-deploy-with-angular-4-8440c9fdd96c
 */
@Injectable()
export class VersionCheckService {
  private _isAppMarkedForHardReload = false;

  // these will be replaced by actual version and hash during build (pre-build.js)
  private _currentHash = 'd1c586496e025d4409dc55559e38d011d7fb3972';
  private _currentVersion = '9.1.23';

  constructor(private http: HttpClient) {}

  /**
   * Checks in every set frequency the version of frontend application
   * @param url the url to fetch the version data from
   * @param frequency in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(
    url: string,
    frequency: number = 1000 * 60 * 30
  ): void {
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

  /**
   * Verifies if the hash has changed, and marks the app for hard reload if so.
   * @param newHash the Hash to match against the existing one
   */
  public shouldHardReloadApp(newHash: string): boolean {
    return (this._isAppMarkedForHardReload = this.hasHashChanged(newHash));
  }

  public get currentHash(): string {
    return this._currentHash;
  }

  public get currentVersion(): string {
    return this._currentVersion;
  }

  public get isAppMarkedForHardReload(): boolean {
    return this._isAppMarkedForHardReload;
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url the url to fetch the version data from
   */
  private checkVersion(url: string): void {
    // We timestamp these requests to invalidate caches
    this.http
      .get(url + '?t=' + new Date().getTime())
      .pipe(first())
      .subscribe(
        (response: { version: string; hash: string }) => {
          this.shouldHardReloadApp(response.hash);
        },
        error => {
          console.error(error, 'Could not get version');
        }
      );
  }

  /**
   * Checks if hash has changed.
   * This file has the hash, if it is a different one than in the version.json
   * we are dealing with version change (unless it is `development`).
   * @param newHash the hash to compare against
   * @returns whether the hash has changed or not
   */
  private hasHashChanged(newHash: string): boolean {
    if (!this.currentHash || !newHash || newHash === 'development') {
      return false;
    }

    return this.currentHash !== newHash;
  }
}
